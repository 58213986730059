@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'nprogress/nprogress.css';
@import '@fortawesome/fontawesome-svg-core/styles.css';

#nprogress .bar {
  background: limegreen !important;
}

.green-gradient-bg {
  background: linear-gradient(45deg, #416a67, #019ba3);
}

.ant-float-btn {
 box-shadow: none;
}


.categoryHome {
  border: solid white 1px;
  flex-grow: 1;
  border-radius: 8px;
  flex-basis: 0;
  text-align: center;
  margin: 16px;
  min-height: 200px;
  position: relative;
  max-width: 500px;
}

.categoryTitleHome {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  font-size: 26px;
  font-weight: bold;
}

.categoryDescriptionHome {
  display: none;
  background-color: #245958;
  height: 100%;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
}

/* .categoryHome:hover .categoryTitleHome {
  display: none;
}

.categoryHome:hover .categoryDescriptionHome {
  display: block;
} */

.css-7nbrit-control{
   border-color: #e5e7eb !important;
}

.css-1nlpctl-control{
    border-color: #e5e7eb !important;
}

.css-1nlpctl-control:hover{
    border-color: #e5e7eb !important;
}

.css-115eup5-control{
    border-color: #0b0f15 !important;
}

.checklistRow {
    display: table;
    width: 100%; /*Optional*/
    table-layout: fixed; /*Optional*/
    border-spacing: 10px; /*Optional*/
}

.checklistColumn {
    display: table-cell;
    background-color: red; /*Optional*/
}

.roundedRectangleButton {
  background-color: #537372;
  border: none;
  color: white;
  padding: 3px;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 10px;
  border-radius: 4px;
  font-weight: bold;
  position: absolute;
  right: 0;
}

.roundedRectangleBorderButton {
  background-color: white;
  border: 2px solid #537372;
  color: #537372;
  padding: 3px;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 10px;
  border-radius: 4px;
  font-weight: bold;
}

.file-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.file-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 2px solid #537372;
  border-radius: 5px;
  width: 150px;
}

.delete-button {
  margin-left: auto;
  cursor: pointer;
}

.ant-result-info .ant-result-icon > .anticon {
  color: red !important;
}

.ant-result-success .ant-result-icon > .anticon {
  color: #3788FD !important;
}

.custom-scrollbar {
  max-height: 70vh;
  overflow-y: auto;

}

.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    margin-top: 40px;
    margin-bottom: 40px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #3788fd;
  border-radius: 10px;
}

.moveLeft {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;

}

.moveLeftSubcategory {
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  width: 200px;
  text-overflow: ellipsis;
}

.ant-tooltip .ant-tooltip-inner {
  min-height: 32px;
  width: max-content;
  padding: 6px 6px;
  white-space: nowrap;
  color: white;
  text-align: start;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.85);
  border-radius: 6px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-tooltip-arrow {
  visibility: hidden;
}

.custom-success-notification .ant-notification-notice-icon-success {
  display: none;
}

.custom-success-notification .ant-notification-notice-close {
  margin-top:2px;
  margin-left:10px;
}
.ant-notification-notice .ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 2px;
  margin-left: 10px;
  margin-right:10px;
  font-size: 16px;
  color:white
}

.ant-collapse-content-box, .ant-collapse-header, .ant-collapse-item-active {
  background-color: white;
}

.custom-select {
  width: 100%;
  margin-top: 2.5px;
  padding: 3.5px;
  border: 2px solid #dbdbdb;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.custom-select:hover,
.custom-select:focus,
.custom-select.ant-select-focused,
.custom-select.ant-select-open {
  border: 2px solid #0099ff;
}

